<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3" v-for="(skill, index) in skills" :key="index">
          <ZoneBox :skill="skill"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ZoneBox from "@/components/ZoneBox";

export default {
  name: 'About',
  components: {ZoneBox},
  computed: {
    skills() {
      return this.$store.state.config.zones && this.$store.state.config.zones.filter(i => i.skill === 'fishing')
    },
  }
}
</script>
